.section1 {
    background-color: white;
    padding: 15% 5% 10% 10% !important;  
    font-family: Montserrat !important;
    margin-right: 0px !important;
}

.section1 > div > h1{
    font-size: 60px;
    font-weight: 900;
}

.section1 > div > h3{
    font-size: 30px;
    font-weight: bolder;
}

.blue{
    color: #00adb5 ;
}

.txt{
    font-weight: bold !important;
    margin: 50px 0 50px 0;
}

.btn3 {
    border-radius: 10px !important;
    border: 4px solid #00adb5 !important;
    padding: 12px 60px 12px 60px !important; 
    font-size: 1.25rem !important;
    background-color: #00adb5 !important ;
    font-weight: bolder !important;
    color: white !important;
}

.img1{ 
    margin-top: -10%;
    margin-left: 10% !important;
}

.extra-bold {
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
} 

.effect:hover{
    box-shadow: 3px 3px 3px grey;
}

@media (max-width: 990px) {
    .img1 {
      display: none !important;
    }  
    .extra-bold {
        margin-top: 3rem;
    } 
  }
  
