.loginBody >div >h2{
  font-size: 40px !important;
  margin-bottom: 10%;
  margin-top: 1% !important;
}

.google{
  border-radius: 10px !important;
  border: 3px solid #00adb5 !important; 
  margin-top: 5%;
  font-size: 1.05rem !important; 
  background-color: #fff !important;
  color: #00adb5 !important;  
  width: 75%;
  display: flex;
  justify-content: center;
}

.foot{
  display: flex;  
  padding: 0% 10% 0% 10%;
  margin-top: 3%; 
  text-align: center;
  width: inherit;
}

.foot > h6{
  font-size:14px;
}

.nobg{
  background: transparent;
  font-size:14px;
}

.loginBody{
background-color: white;
padding: 12% 5% 3% 10% !important;  
font-family: Montserrat !important;
margin-right: 0px !important;  
}

.loginImage{
  width:90%;
  height:auto;
}

@media (max-width: 990px) {
  .loginImage {
    display: none !important;
  } 
  .loginBody { 
      margin-top: 3.5rem;
  }  
}

