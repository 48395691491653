/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;  
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #00ADB5;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #00ADB5;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .shareable{ 
      margin-top: 5%;;
  } 

  .editing{
    background-color: #02a0a8 !important;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copied{
    margin-top: 3%;
    color: #00ADB5;
    font-style: italic;
    font-size:20px;
  }
  
  .slider-fix{
    display: flex;
    justify-content: flex-end; 
    margin-top: 1.2%;
  }

  .shareable-fix{
    display: flex;
    justify-content: flex-start;
  }

  .modal-Btn{ 
    border-radius: 10px !important;
    border: 4px solid #00adb5 !important;
    padding: 10px 30px 10px 30px !important; 
    font-size: 1.25rem !important;
    background-color: #00adb5 !important ;
    font-weight: bolder !important;
    color: white !important;
    width:75%;
    margin-top: 5%;
  } 

  .note{ 
    font-style: italic;
    color: #808080;
    margin-top: 5%;
  }