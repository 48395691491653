.uploadBtn {
    background-color: #00adb5;
    color: white;
    padding: 0.5rem;
    font-family: sans-serif; 
    cursor: pointer; 
    width:100%;  
    text-align: center;
} 

.save_btn {
    text-align: center;
    background-color: #00adb5; 
    color: white;
    padding: 0.5rem; 
    width : 100%;
    font-family: sans-serif; 
    cursor: pointer;  
    position: relative; 
    margin-top: 5%; 
    border: none; 
} 

.save_div{
    width:inherit; 
    display: flex; 
}
 
 

