.navbar {
    font-size: 1.25em;
    padding: 1% 5% 1% 5% !important;
    background-color: #eeeeee; 
    font-family: Montserrat;
    min-height: 3.5em !important;
    box-shadow: 2px 2px 5px #888888;
  }
  
  .nav-link {
    display: flex !important;
    align-items: center !important;
  }
  
  .nav-item:hover , .nav-item:active{
    font-weight: bold;
    color:black
  }
  
  a.disabled {
    pointer-events: none; 
  }
  
  .nav-item {
    color: black;
  }

  .logo- {
    width:3.5rem;
    height: auto;
  }

  .logo-margin {
    margin-right: 8%;
  }
  
  .btn {
    border-radius: 10px !important;
    border: 4px solid #00adb5 !important;
    padding: 12px 40px 12px 40px !important;
    max-height: 2.5rem;
    font-size: 1.05rem !important;
  }
  
  .ml{
    margin-left: 40px;
  }
  
  .button1 {
    background-color: #00adb5 !important ;
    font-weight: bolder !important;
    color: white !important;
  }

  .button2 {
    background-color: transparent;
    font-weight: bold !important;
    color: black;
  }
  
  .button:focus , .button:hover{ 
    outline: none !important;
  }

  .button:hover{
    box-shadow: 5px 4px 5px #888888 !important
  }
  
 .logoutBtn{ 
    height:40px !important;
    width:auto;
 }

 .navbar-icon{
  display: flex;
}

.dot{
  color: #00adb5 !important;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.noBorder{
  border:none;
}

.navbar-center{
  display: flex;
  justify-content: center;
}
  
@media (max-width: 1300px) {
  .dot {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  } 
  .nav-item{
    width:fit-content !important; 
    white-space: nowrap;
  }
}

@media (max-width: 990px) {
  .dot {
    display: none !important;
  } 
  .collapse-hide {
      display: none !important;
      margin-top: 5%;
  }  
}

@media (min-width: 990px) {
  .collapse-show {
    display: none !important;
  } 
  .navbar-right{
    margin-left: 8rem;
  } 
}