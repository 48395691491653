.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background: rgba(0, 0, 0, 0.75);
  }
  
  .modal {
    position: fixed;
    top: 20vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    padding: 3% 5% 3% 5%;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .header { 
    padding: 1rem;
  }
  
  .header h2 {
    margin: 0;
    color: black;
    font-weight: bolder;
  }
  
  .content {
    padding: 1rem;
    font-size: 1.8rem;
    margin-top: 2%; 
  }
  
  .actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }


.modalBtn{ 
    border-radius: 10px !important;
    border: 4px solid #00adb5 !important;
    padding: 10px 30px 10px 30px !important; 
    font-size: 1.25rem !important;
    background-color: #00adb5 !important ;
    font-weight: bolder !important;
    color: white !important;
    width:75%;
    margin-top: 10%;
  } 

.yesNoBtn{
  margin-top: 5% !important;
}

  @media (min-width: 768px) {
    .modal {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }