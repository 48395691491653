.grey{
    background: #666D73;
    min-height: 68em;
}
 
.editor-page{
    margin-top: 4.6em;
}

.split {
    display: flex;
    flex-direction: row;
    min-width: 100vw;
}

.gutter {
    background-color: grey;
    background-repeat: no-repeat;
    background-position: 50%;
    z-index: 20;
}

.notShareable{
    text-align: center;
    color: #00adb5;
    margin-top: 20%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
} 

@media (max-width: 1300px) {
    .editor-page{
        margin-top: 4.5em;
    }
  }
@media (min-width: 1700px) {
    .editor-page{
        margin-top: 5.1em;
    }
  }

  @media (min-width: 2000px) {
    .editor-page{
        margin-top: 5.3em;
    }
  }
 