.projects {
  background-color: #fff !important ;
  padding: 1% 1% 1% 1% !important;
  font-family: Montserrat !important;
  margin-top: 8vw;
}

.card-img {
  border-radius: 10px !important;
  position: relative;
}

.projectBtn {
  border-color: transparent !important;
  background: transparent;
  color:white
}  

.overlay {
  position: absolute;  
  background: rgba(0, 0, 0, 0.6);   
  transition: .5s ease;
  opacity:0; 
  z-index: 10; 
  border-radius: 10px !important;
}
 
.container:hover .overlay {
  opacity: 1;
}

.container:hover{
  color:grey
}

.overlayIcons{
  padding:10px  10px ;
  width:60px;
}

.image-container {
  position: relative; 
}

.image-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #FFF;
}

.image-container:hover .after {
  display: block;
  border-radius: 18px;
  background: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.noProject{ 
  text-align: center !important; 
}

.noProject h1{  
  margin-top: 4.5%;
}

.noProject img{ 
  width:29%;
  height: auto;
}

.extra-bold {
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
} 

@media (max-width: 990px) { 
  .extra-bold {
      margin-top: 3rem;
  } 
}